<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <SideBar />
            </div>
            <div v-permission="['create']" class="mt-5 flex justify-center">
                <btn @onClick="$router.push({ name: 'permission_route_set', params: { parentId: 'root' } })">
                    <i class="fas fa-plus"></i>
                    <span class="ml-2">新增主路由</span>
                </btn>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative bg-white">
            <div class="py-2 border-b-2 border-gray-100">
                <ul class="flex py-2 px-5">
                    <li class="flex-1">路由名稱</li>
                    <li v-permission="['update']" class="max-w-[100px] min-w-[100px] text-center">新增子路由</li>
                    <li v-permission="['update']" class="max-w-[100px] min-w-[100px] text-center">編輯路由</li>
                    <li v-permission="['delete']" class="max-w-[100px] min-w-[100px] text-center">刪除路由</li>
                </ul>
            </div>
            <div class="treeBox p-5">
                <el-tree :data="datas" node-key="id" :expand-on-click-node="true" :icon-class="'el-icon-arrow-right text-xl text-black'">
                    <div slot-scope="{ data }" class="flex w-full">
                        <div class="flex-1 text-black">
                            {{ data.name }}({{ data.key }})
                            <span v-if="data.is_menu" class="text-red-600">左側選單</span>
                            <span v-if="data.is_child_feature" class="text-blue-600">子功能</span>
                        </div>
                        <button
                            v-if="data.parent_id === null"
                            v-permission="['update']"
                            class="min-w-[100px] text-sm text-red-500"
                            @click.prevent="onAddChildRoute(data.id)">
                            <i class="fas fa-plus"></i>
                        </button>
                        <button v-permission="['update']" class="min-w-[100px] text-sm text-red-500" @click.stop="onEdit(data.id)">
                            <i class="far fa-edit"></i>
                        </button>
                        <button v-permission="['delete']" class="min-w-[100px] text-sm text-red-500" @click.stop="onDelete(data.id, data.name)">
                            <i class="far fa-trash-alt"></i>
                        </button>
                    </div>
                </el-tree>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SideBar from "../Sidebar.vue";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    name: "PermissionRouteList",
    components: {
        SideBar,
        Btn,
    },
    data() {
        return {
            // 標題
            titles: ["", "", "", ""],
            // 列表資料
            datas: [
                // {
                //     id: 1,
                //     name: "數據中心",
                //     key: "dashboard",
                //     children: [{ id: 4, name: "數據中心2", key: "dashboard2" }]
                // }
            ],
            loading: false,
            defaultProps: {
                children: "children",
                label: "name",
            },
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        async getPermissionsApi() {
            const { status, data } = await this.$api.GetPermissionsApi().then((res) => {
                return { data: res.data.permissions, status: res.status };
            });
            if (status === 200) {
                this.datas = [...data];
            }
        },
        /**
         * 新增子路由
         * @param { type Number(數字) } parentId 上層 id
         */
        onAddChildRoute(parentId) {
            this.$router.push({ name: "permission_route_set", params: { parentId } });
        },
        /**
         * 編輯路由
         * @param { type Number(數字) } id 路由 id
         */
        onEdit(id) {
            this.$router.push({ name: "permission_route_set", params: { id } });
        },
        /**
         * 刪除路由
         * @param { type Number(數字) } id 路由 id
         * @param { type String(字串) } name 路由名稱 name
         */
        async onDelete(id, name) {
            this.loading = true;
            const opts = {
                title: "刪除資料",
                message: `你確認刪除 <span class="text-red-600"> ${name} </span> 路由嗎？`,
            };
            this.$pop
                .popConfirm(opts)
                .then(
                    async () => {
                        await this.$api.DeletePermissionsApi(id);
                        this.$message({
                            type: "success",
                            message: "刪除路由成功",
                        });
                        await this.getPermissionsApi();
                    },
                    () => {
                        return;
                    }
                )
                .catch((err) => {
                    this.errorCallback({ err });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getPermissionsApi();
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tree-node__content {
    height: 50px;
}
.custom-tree-node {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-right: 8px;
}

.el-tree-node__expand-icon .el-icon-caret-right {
}
.treeBox {
    .el-tree {
        @apply w-full rounded-lg;
    }
    @apply w-full rounded-lg;
}
</style>
